// Core dependencies
import Vue from 'vue';

import TpIcon from './components/TpIcon.vue';
import TpButton from './components/TpButton.vue';
import TpCard from './components/TpCard.vue';

// Disable vue devtools in production
if (process.env.NODE_ENV === 'production') {
  Vue.config.devtools = false;
}

Vue.component('tp-icon', TpIcon);
Vue.component('tp-button', TpButton);
Vue.component('tp-card', TpCard);

new Vue({
  el: '#app',
  methods: {
    onClickButton() {
      window.open('https://play.google.com/store/apps/details?id=net.token_pay&launch=true', '_blank');
    }
  }
});
