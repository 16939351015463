<template>
  <div class="tp-card">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import './resources/css/base/variables';

.tp-card {
  background-color: #fff;
  border-radius: 15px;
  margin: 20px 0;
  padding: 30px;
  box-shadow: $shadow;
  width: 100%;
  text-align: center;
}
</style>
