<template>
  <div class="tp-button" @click="$emit('click')">
    <tp-icon class="tp-button__icon" :name="icon" v-if="icon" />
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    }
  }
};
</script>

<style lang="scss">
@import './resources/css/base/variables';

.tp-button {
  color: #fff;
  background: map-get($colors, primary);
  padding: 15px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: .2s all;
  margin: 20px 0;

  &__icon {
    margin-right: 10px;
  }

  &:hover {
    box-shadow: $shadow;
  }
}
</style>
